import React from "react"
import QRCodeContainer from "./qrcode_container"

export default function QRCodeModal({modalRef, url, appStoreUrl, googlePlayUrl}) {
  const closeQrCodeModal = () => {
    const modal = modalRef.current
    modal.close()

    const body = document.querySelector("body")
    body.classList.remove("overflow-y")
  }

  return (
    <dialog id="qrCodeModal" className="qr-code-modal" ref={modalRef}>
      <div className="modal-body">
        <div className="qr-code-close-button-wrapper">
          <span className="close-qr-modal" onClick={() => closeQrCodeModal()}>&times;</span>
        </div>
        <QRCodeContainer url={url} />
        <div className="download">
          <a href={appStoreUrl}><img src="images/modal_appstore.svg" alt="logo da app store"></img></a>
          <a href={googlePlayUrl}><img src="images/modal_playstore.svg" alt="logo da play store"></img></a>
        </div>
      </div>
    </dialog>

  )
}