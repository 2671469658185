import * as React from "react"
import {PropsWithoutRef, useState} from "react";

export default function InstallApp(props: PropsWithoutRef<{url: string, open: boolean, close: () => void}>) {
  const {open, close, url} = props

  return (
    <div id="abrirModal" className="modal" hidden={!open}>
      <div>
        <h3>Bora ganhar cashback?!</h3>
        <br/>
        <div>
          <div className={"modal-row"}>
            <img width={37} src="/images/logo.svg" alt="dinerama"/>
            <a href={url} className={"modal-open"} onClick={close} >Abrir no App Dinerama</a>
          </div>
          <br/>
          <div className={"modal-row"}>
            <img width={37} src="/images/browser.svg" alt="dinerama"/>
            <a href={"#continue"} onClick={(e)=>{e.preventDefault();close()}} className={"modal-continue"}>Continuar no Navegador</a>
          </div>
        </div>
      </div>
    </div>

  )
}