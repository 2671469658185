import * as React from "react"
import { Link } from "gatsby"

export default function Introduction_case() {
  return (
    <div className="introduction_case">
      <div className="intro">
        <div className="cardtype row">
          <img className="image-for-card" src="images/beer.png" alt="" />
          <span>Cervejaria</span>
        </div>
        <h4 className="tittle">Dinerama turbina lançamento de produtos</h4>
        <p className="description-case-beer">Usando as soluções da Dinerama, essa marca lançou com sucesso uma nova cerveja e
          atingiu seus objetivos de sampling, awareness e depois loyalty</p>
        <div className="action">
          <Link to={"/partners"}>Quero ser parceiro Dinerama</Link>
        </div>
      </div>
    </div>
  )
}