import React from "react";
import {Link} from "gatsby";
import * as styles from "../styles/be-our-patner-cases.css"

export default function WantToBeOurPartnerCases() {
  return (
    <div className="be-our-partner-case">
      <div className="intro-case">
        <h4 className="title-case">Quer ser nosso parceiro?</h4>
        <p className="description-case">Aumente suas vendas e obtenha dados de sell-out de qualquer supermercado</p>
      </div>
      <div className="features-case">
        <div className="feature-case">
          <img src="/images/increase_market_share_case.png" alt=""></img>
          <h5>Aumente market share</h5>
          <p>Atraia novos consumidores, aumente o tamanho da cesta e a frequência de compra</p>
        </div>
        <div className="feature-case">
          <img src="/images/one_on_one_connection_case.png" alt=""></img>
          <h5>Conexões 1:1</h5>
          <p>Teste lançamentos de novos produtos, obtenha insights a nível CPF de comportamento de compra e política de pricing</p>
        </div>
        <div className="feature-case">
          <img src="/images/close_to_loop_case.png" alt=""></img>
          <h5>Close the loop</h5>
          <p>Mensure resultados de campanha em qualquer canal e recompras com informações de sell-out e geolocalização</p>
        </div>
      </div>
      <div className="action">
        <Link to={"/partners"}>Entenda mais sobre essa parceria</Link>
      </div>
    </div>
  );
}