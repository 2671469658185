import * as React from "react"

export default function ResultDeliveredCase() {
  return (
    <div className="result-delivered-case">
      <div className="intro">
        <h4 className="tittle-result">Resultado entregue</h4>
        <div className="description-for-card">
          <div className="porcent-for-graphic-result">
            <div className="vertical-line-primary">
              <p className="tittle-for-vertical-line">Market Share no lançamento</p>
              <p className="description-for-vertical-line">20%</p>
            </div>
            <div className="vertical-line">
              <p className="tittle-for-vertical-line">Market Share com Loyalty</p>
              <p className="description-for-vertical-line">7%</p>
            </div>
          </div>
        </div>
        <p className="description">A campanha atingiu um pico de 20% de market share de cervejas durante o mês de
          lançamento e depois conseguiu manter um patamar 7% de market share com o
          programa de loyalty</p>
      </div>
      <img className="image-market-share" src="images/Case-Market-Share2.png" alt=""></img>
    </div>
  )
}