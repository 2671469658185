import * as React from "react"

export default function SucessMeasurements() {
  return (
    <div className="sucess-measurements">
      <div className="intro-sucess-measurements">
        <h4 className="tittle-sucess-measurements">Métricas de sucesso com a Dinerama</h4>
        <p className="description-sucess-measurements">A marca cliente atingiu ROAS (return on ad spent) de 8,4X na campanha de loyalty e de
          2,1X na campanha de sampling.</p>
      </div>
      <div className="row last-line-measurements box-padding">
        <div className="cases-sucess-intro">
          <h4 className="cases-roas">ROAS</h4>
          <p className="loyalty">Loyalty</p>
        </div>
        <div className="signal">
          <h4 className="tittle-purchase-campaings">=</h4>
        </div>
        <div className="circle-campaings">
          <div className="circle-campaings-teste">
            <h4 className="tittle-circle">8,4x</h4>
            <p className="description-circle">Return on ad spend</p>
            <h4 className="tittle-circle">2,1x</h4>
            <p className="description-circle">Sampling</p>
          </div>
        </div>
      </div>
    </div>
  )
}