import React from "react"
import { Link } from "gatsby"

export default function OurHeaderCases() {
  const openQrCodeModal = () => {
    const modal = document.querySelector("#qrCodeModal") as HTMLDialogElement
    modal.showModal()

    const body = document.querySelector("body")
    body.classList.add("overflow-y")
  }

  return (
    <div className="header-cases">
      <a className="logo" href="http://www.dinerama.com.br">
        <img src="images/logo-name.svg" alt="dinerama"></img>
      </a>
      <div className="menu-toggle-wrapper-cases">
        <input id="menu-toggle-cases" type="checkbox"/>
        <label className='menu-button-container-cases' htmlFor="menu-toggle-cases">
          <div className='menu-button-cases'></div>
        </label>
        <ul className="mobile-menu-list-cases">
          <li><Link to="/#">Home</Link></li>
          <li><Link to="/#how-to/">Como usar</Link></li>
          <li><Link to="/#cases">Cases</Link></li>
          <li><Link to="/partners/">Para empresas</Link></li>
          <li><Link to="/#faq">Ajuda</Link></li>
        </ul>
      </div>

      <ul className="menu-list">
        <li><Link to="/#">Home</Link></li>
        <li><Link to="/#how-to">Como usar</Link></li>
        <li><Link to="/#cases">Cases</Link></li>
        <li><Link to="/partners/">Para empresas</Link></li>
        <li><Link to="/#faq">Ajuda</Link></li>
      </ul>
      <a className="btn earn-cashback-button" onClick={() => openQrCodeModal()}>Ganhe cashback</a>
    </div>
  )
}