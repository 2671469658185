import * as React from "react"

export default function ObjectivesAchievedCase() {
  return (
    <div className="objectives-achieved-case">
      <div className="intro-achieved-case">
        <h4 className="tittle-achieved">Resultados Comprovados</h4>
        <p className="description-for-achieved">A tecnologia da Dinerama permitiu à essa marca fazer o lançamento de uma campanha nacional de sampling em D0.
          Após o período de experimentação e awareness, foram utilizados os dados proprietários da
          Dinerama para a criação de audiências qualificadas que resultaram em maior recorrência de compra.</p>
        <img className="graphic-for-objectives-achieved" src="/images/Objectives_achieved2.png" alt="" />
      </div>
    </div>
  )
}